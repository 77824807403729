@import '../../styles/abstract/variables';
@import '../../styles/abstract/mixins';

.container {
  background: $color-primary-dark-blue;
  height: 186px;
}

.innerContainer {
  height: 100%;
}

.innerWrapper {
  padding: 32px 0 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.upperWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.lowerWrapper {
  padding-bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.socialsWrapper {
  display: flex;
  align-items: center;
}

.image {
  display: block;
}

.copyrightText {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 12px;
  color: white;
}

.link {
  font-family: 'SuisseIntl-Regular', sans-serif;
  text-decoration: none;
  color: white;
  font-size: 12px;
}

.link {
  padding: 8px 12px;
}

.navigation {
  display: flex;
  cursor: pointer;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li:hover {
    .link {
      color: $color-primary-yellow;
    }
  }
}

.icon {
  color: white;
}

.icon:hover {
  color: $color-primary-yellow;
}

@media only screen and (max-width: $project-screen-mobile-landscape) {
  .navigation {
    flex-direction: column;
  }
}
