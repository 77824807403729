@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.container {
  width: 480px;
  background: white;
  border: $box-shadow-1;

  @include media-max($project-screen-mobile-portrait-lg) {
    width: 300px;
    height: 400px;
  }
}

.title {
  margin-bottom: 12px;
  font-family: 'SuisseIntl-Bold', sans-serif;
  color: $color-primary-dark-blue;
  font-size: 20px;
}

.subtitle {
  font-family: 'SuisseIntl-Regular', sans-serif;
  color: $color-primary-dark-blue;
  font-size: 16px;
}

.bottomSubtitle {
  margin: 8px 0 28px;
  font-family: 'SuisseIntl-Regular', sans-serif;
  color: $color-primary-dark-blue;
  font-size: 16px;
  text-align: center;
}

.viewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}

.disconnectedViewButton {
  border-radius: 4px;
  background: $color-primary-yellow;
  background: $color-primary-yellow-gradient-reverse;
  border: $color-primary-yellow-border-dark-thin;
  padding: 8px 16px;
  font-family: 'SuisseIntl-Medium', sans-serif;
  font-size: 12px;
  color: #323200;
  text-transform: uppercase;
}

.disconnectedViewButton:hover {
  background: $color-primary-yellow-dark-1;
  background: $color-primary-yellow-gradient-reverse-darker;
}

.loadingViewText {
  font-family: 'SuisseIntl-Medium', sans-serif;
  font-size: 12px;
  color: $color-primary-yellow-dark-3;
  text-transform: uppercase;
}

.cameraViewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cameraViewTitle {
  margin-bottom: 8px;
  font-family: 'SuisseIntl-Bold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.cameraViewTitleRed {
  color: #ae0000;
}

.cameraViewButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: $color-white-low-opacity;
  border: none;
}

.cameraViewButton:hover .cameraViewButtonInnerCircle,
.cameraViewButton:hover .cameraViewButtonInnerSquare {
  background: $color-red-high-opacity;
}

.cameraViewButtonInnerCircle {
  width: 100%;
  height: 100%;
  border-radius: 48px;
  background: $color-red-medium-opacity;
}

.cameraViewButtonInnerSquare {
  width: 60%;
  height: 60%;
  border-radius: 6px;
  background: $color-red-medium-opacity;
}

.cameraViewDot {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: $color-red-high-opacity;
  animation: dot-pulsate 2s infinite;
}

.renderActionsContainer {
  width: 100%;
  background: transparent;
  position: absolute;
  height: 100%;
}

.renderActionsInnerWrapper {
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

@keyframes dot-pulsate {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 $color-red-high-opacity;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px $color-red-no-opacity;
  }

  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 $color-red-no-opacity;
  }
}
