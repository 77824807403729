@import '../../styles/abstract/variables';

.container {
  position: relative;
  min-height: calc(100vh - (186px + 80px));
  background: white;
}

.contentDiv {
  position: absolute;
  background: $color-background;
}

.appBarDiv {
  position: fixed;
  z-index: 3;
}
