@import '../../../styles/abstract/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ccd2d8;
  color: $color-primary-dark-blue;
  padding: 8px 18px 8px 14px;
  background-color: transparent;
}

.title {
  display: flex;
  align-items: center;
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 15px;
  color: $color-primary-dark-blue;
  margin: 4px 16px 0 12px;
}

.icon {
  display: flex;
}

.button {
  border: none;
  background-color: transparent;
  padding: 0;
}
