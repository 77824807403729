@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.container {
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ccd2d8;
  color: $color-primary-dark-blue;
  cursor: pointer;
  padding: 8px 26px 8px 18px;
  background-color: transparent;

  @include media-max($project-screen-mobile-portrait-lg) {
    width: 100%;
  }
}

.icon {
  display: flex;
  margin-right: 16px;
}
