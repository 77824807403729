/* Font Size */

$project-font-size-body: 16px;

/* Colors */

$project-color-white: #ffffff;
$project-color-black: #00131e;
$project-color-gray-yellow: #fff200;
$project-color-gray-green: #0e6e7c;
$project-color-gray-01: #767676;
$project-color-warning: #bd3547;
$project-color-text: $project-color-white;
$project-color-app-bg: $project-color-white;

$color-background: #f7f7f7;
$color-login-background: #f2f0e9;
$color-primary-dark-blue: #001b2b;
$color-primary-dark-blue-2: #071a2a;
$color-primary-dark-blue-medium-opacity: rgba(0, 27, 43, 0.4); // #001b2b
$color-primary-light-green: #314422;
$color-primary-yellow: #fff200;
$color-white-low-opacity: rgba(255, 255, 255, 0.25);
$color-red-no-opacity: rgba(255, 0, 0, 0);
$color-red-medium-opacity: rgba(255, 0, 0, 0.5);
$color-red-high-opacity: rgba(255, 0, 0, 0.75);
$color-yellow-no-opacity: rgba(255, 242, 0, 0);
$color-yellow-high-opacity: rgba(255, 242, 0, 0.75);
$color-primary-yellow-dark-1: #ccc200;
$color-primary-yellow-dark-2: #999100;
$color-primary-yellow-dark-3: #665e00;
$color-primary-light-yellow: rgba(255, 242, 0, 0.2);
$color-light-blue: #009999;
$color-gray: #f3f1eb;

$color-primary-yellow-gradient: linear-gradient(
  180deg,
  #ccc200 0%,
  #fff200 100%
);
$color-primary-yellow-gradient-reverse: linear-gradient(
  180deg,
  #fff200 0%,
  #ccc200 100%
);
$color-primary-yellow-gradient-reverse-darker: linear-gradient(
  180deg,
  #fff200 0%,
  #b3aa00 100%
);

$color-primary-yellow-border-dark-1: 4px solid #ccc200;
$color-primary-yellow-border-dark-thin: 1px solid #ccc200;
$color-primary-yellow-border-dark-2: 4px solid #b3aa00;
$color-primary-yellow-border-dark-3: 4px solid #665e00;
$color-border-gray-1: 1px solid #ccd2d8;
$color-border-gray-2: 1px solid #cdcdcf;

$box-shadow-1: 0 2px 5px rgba(0, 0, 0, 0.5);

/* Screens */

$project-screen-pad-portrait: 1200px;
$project-screen-laptop: 900px;
$project-screen-mobile-landscape: 600px;
$project-screen-mobile-portrait-lg: 480px;
