@import '../../styles/abstract/variables';

.buttonStyle {
  padding: 20px;
  color: $color-primary-dark-blue;
  background-color: $color-primary-yellow;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.buttonText {
  color: $color-primary-dark-blue;
  font-family: SuisseIntl-Bold, sans-serif;
  font-size: 16px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 20px;
  font-family: 'SuisseIntl-Bold', sans-serif;
  color: $color-primary-dark-blue;
  margin-top: 8px;
}

.subtitle {
  font-size: 16px;
  font-family: 'SuisseIntl-Regular', sans-serif;
  color: $color-primary-dark-blue;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 22px 0 22px 0;
}

.mainGrid {
  padding: 0 5%;
  width: 400px;
}

.downloadGrid {
  background: #f0f1f2;
  width: 100%;
  padding: 13px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.downloadTitle {
  flex-direction: row;
  display: flex;
  align-items: center;
}
