.buttonsWrapper {
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 48px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.declineButton {
  background: #edf1f7;
  color: #ff4d67;
}

.confirmButton {
  background: #001b2b;
  color: white;
}
