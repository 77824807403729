@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.container {
  position: relative;
  padding: 32px;
  width: 320px;
  background: white;
  border: $box-shadow-1;
  border-radius: 20px;

  @include media-max($project-screen-mobile-portrait-lg) {
    padding: 16px;
  }
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}

.controlsTimerWrapper {
  margin-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlsButton {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
}

.timer {
  padding-left: 18px;
  height: 44px;
  width: 148px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  background: $color-gray;
}

.timerNumber {
  font-family: 'SuisseIntl-Medium', sans-serif;
  font-size: 20px;
  color: $color-primary-dark-blue;
}

.timerSeparator {
  margin: 0 4px;
}

.uploadRecordingButton {
  margin-top: 24px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  border: $color-border-gray-2;
  border-radius: 5px;
  background: transparent;
  color: black;
}

.uploadImage {
  display: block;
  margin: 0 16px;
}

.uploadText {
  font-size: 15px;
  color: $color-primary-dark-blue-2;
}
