@import '../../../styles/abstract/variables';
@import '../../../styles/abstract/mixins';

.button {
  width: 335px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  background-color: $color-primary-yellow;
  color: $color-primary-dark-blue;
  font-family: 'SuisseIntl-Bold', sans-serif;
  font-size: 16px;

  @include media-max($project-screen-mobile-portrait-lg) {
    width: 100%;
  }
}

.disabled {
  background-color: $color-primary-light-yellow;
  color: $color-primary-light-green;
  font-family: 'SuisseIntl-Bold', sans-serif;
  cursor: default;
}

.inverted {
  background-color: $color-primary-dark-blue;
  color: $color-primary-yellow;
}

.small {
  width: 255px;
  height: 50px;
}

.medium {
  width: 309px;
}

.outline {
  border: 1px solid $color-primary-dark-blue-2;
  background-color: transparent;
}

.login {
  width: 100%;
}
