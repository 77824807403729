/**
 * SASS mixins and functions
 *
 */

@function px-to-rem($value) {
    @return #{$value / strip-units($project-font-size-body)}rem;
}

@mixin media-max ($media) {
    @media only screen and (max-width: $media) {
        @content;
    }
}
