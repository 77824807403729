@import 'src/styles/abstract/variables';

.container {
  margin-top: 32px;
}

.mainWrapper {
  margin-top: 12px;
  border: $color-border-gray-1;
  border-radius: 5px;
  padding: 20px 20px 10px;
}

.title {
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 24px;
  color: $project-color-black;
}

.textarea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  border-bottom: $color-border-gray-1;
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 17px;
  color: $color-primary-dark-blue;
  padding-bottom: 12px;
}

.icon {
  display: flex;
  margin-right: 16px;
}

.button {
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: $color-border-gray-1;
  color: $color-primary-dark-blue;
  cursor: pointer;
  padding: 8px 26px 8px 18px;
  background-color: transparent;
}

.detailsButtonWrapper {
  display: flex;
}

.tooltipWrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.tooltip {
  position: absolute;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, -50%, 0);
  box-shadow: $box-shadow-1;
  background: white;
  border-radius: 5px;
}

.tooltip:after {
  content: '';
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
  background: white;
  position: absolute;
  box-shadow: $box-shadow-1;
  z-index: -1;
  bottom: -10px;
  left: calc(50% - 10px);
}

.tooltipInner {
  padding: 16px;
  background: white;
  border-radius: 5px;
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 14px;
}

.inputWrapper {
  margin: 4px;
  width: 100%;
  height: 55px;
  background: $project-color-white;
  border-radius: 5px;
  font-size: 17px;
  display: flex;
  padding: 16px;
  border: 2px solid $color-primary-yellow;
}
