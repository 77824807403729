@import '../../styles/abstract/variables';
@import '../../styles/abstract/mixins';

.container {
  padding: 32px 0 96px;

  @include media-max($project-screen-pad-portrait) {
    padding: 32px 32px 96px;
  }

  @include media-max($project-screen-mobile-portrait-lg) {
    padding: 32px 8px 96px;
  }
}

.indicatorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.indicatorLineWrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicatorLine {
  position: relative;
  width: 100%;
  height: 2px;
  background: #eaeef2;
}

.indicatorThumb {
  font-family: 'SuisseIntl-Bold', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  z-index: 1;
}

.returnButton {
  margin: 0 0 24px;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 17px;
  color: $color-primary-dark-blue;
}

.returnButtonTitle {
  margin-left: 4px;
}

.mainWrapper {
  margin: 36px 0 0;
}

.title {
  margin: 0;
  padding: 0;
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 35px;
  color: $project-color-black;

  @include media-max($project-screen-mobile-portrait-lg) {
    text-align: center;
  }
}

.subtitle {
  margin-top: 14px;
  margin-bottom: 16px;
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 22px;
  color: $project-color-black;

  @include media-max($project-screen-mobile-portrait-lg) {
    text-align: center;
  }
}

.instructions {
  margin-bottom: 8px;
  font-family: 'SuisseIntl-Bold', sans-serif;
  font-size: 16px;
  color: $project-color-black;

  @include media-max($project-screen-mobile-portrait-lg) {
    text-align: center;
  }
}

.paragraph {
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 22px;
  color: $project-color-black;

  @include media-max($project-screen-mobile-portrait-lg) {
    text-align: center;
  }
}

.dropdownsWrapper {
  margin: 16px 0 36px;
  display: flex;
  width: fit-content;

  @include media-max($project-screen-mobile-landscape) {
    width: 100%;
    flex-direction: column;
  }
}

.leftDropdown {
  margin-right: 16px;

  @include media-max($project-screen-mobile-landscape) {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.itemListWrapper {
  border-radius: 5px;
  padding: 4px;
}

.itemWrapper {
  aspect-ratio: 1.35;
  border-radius: 5px;
  cursor: pointer;
  padding: 4px;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 16px;
}

.selectedItemCheckedIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  background: black;
}

.itemIcon {
  margin-bottom: 25px;
  max-width: 45px;
  max-height: 45px;
}

.itemTitle {
  font-family: 'SuisseIntl-Medium', sans-serif;
  font-size: 18px;
  text-align: center;
}

.textWrapper {
  position: absolute;
  top: 60%;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.submitButtonWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  @include media-max($project-screen-mobile-landscape) {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.detailsTitle {
  font-family: 'ArgestaDisplay-Regular', sans-serif;
  font-size: 24px;
  color: $project-color-black;

  @include media-max($project-screen-mobile-landscape) {
    text-align: center;
  }
}

.detailsWrapper {
  margin-top: 32px;
}

.detailsBoxWrapper {
  margin-top: 12px;
  border: 1px solid #ccd2d8;
  border-radius: 5px;
  padding: 20px 20px 10px;
}

.animateBorder {
  box-shadow: 0 0 0 5px $color-primary-yellow;
  //animation: border-flicker 2s infinite;
}

.detailsButtonsWrapper {
  display: flex;

  @include media-max($project-screen-mobile-portrait-lg) {
    flex-direction: column;
  }
}

.detailsLeftButton {
  margin-right: 12px;

  @include media-max($project-screen-mobile-portrait-lg) {
    margin: 0 0 12px 0;
  }
}

.fileIconWrapper {
  margin-top: 8px;
}

.fileIcon {
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.discardButton {
  @include media-max($project-screen-mobile-landscape) {
    margin-bottom: 16px;
  }
}

@keyframes border-flicker {
  0% {
    box-shadow: 0 0 0 0 $color-yellow-no-opacity;
  }

  50% {
    box-shadow: 0 0 0 5px $color-yellow-high-opacity;
  }

  100% {
    box-shadow: 0 0 0 0 $color-yellow-no-opacity;
  }
}
